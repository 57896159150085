import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/elements.js'
// 导入全局样式
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入NProgress, 包对应的JS和CSS
import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
// Vue.prototype.$axios = axios
// 本地访问地址
// axios.defaults.baseURL = 'http://192.168.36.55:8001/crdc'
// 虚拟机
// axios.defaults.baseURL = 'http://192.168.36.79:8083/crdc'
// 发布后访问地址
axios.defaults.baseURL = 'https://crdc.lianglongcredit.com/json/'
axios.interceptors.request.use(config => {
  NProgress.start()
  // console.log(config)
  // 为请求头对象，添加token验证的Authorization字段
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})
// token失效处理
axios.interceptors.response.use(response => {
  if (response) {
    switch (response.data.status) {
      case '403':
        window.sessionStorage.setItem('token', null)
        router.replace({
          path: '/login'
        })
    }
  }
  return response
}, error => {
  return Promise.reject(error.response.data) // 返回接口返回的错误信息
})
// 设置请求头
// axios.defaults.headers.post['Content-type'] = 'application/json'
// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios
// Vue.prototype.axios = axios
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由懒加载
const Users = () => import('../views/user/UserList.vue')
const Login = () => import('../views/Login.vue')
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  { path: '/login', component: Login },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/user',
    name: 'UserList',
    component: () => import('../views/user/UserList.vue')
  },
  {
    path: '/home',
    name: 'Layout',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/company/pd',
        name: 'CompanyPd',
        component: () => import('../views/manage/CompanyPd.vue')
      },
      {
        path: '/manage',
        name: 'CompanyAdditional',
        component: () => import('../views/manage/CompanyAdditional.vue')
      },
      {
        path: '/user/list',
        component: Users
      },
      {
        path: '/flow',
        name: 'FlowTask',
        component: () => import('../views/manage/FlowTask.vue')
      },
      {
        path: '/flow/defaults',
        component: () => import('../views/manage/FlowTaskDefault.vue')
      },
      {
        path: '/manage/history',
        component: () => import('../views/manage/CompanyAdditionalHistory.vue')
      },
      {
        path: '/manage/newbond',
        component: () => import('../views/manage/NewBond.vue')
      },
      {
        path: '/system'
      },
      {
        path: '/system/user',
        component: () => import('../views/system/user/index.vue')
      },
      {
        path: '/defend'
      },
      {
        path: '/defend/news',
        component: () => import('../views/defend/news/index.vue')
      },
      {
        path: '/flow/news',
        component: () => import('../views/defend/flow/index.vue')
      },
      {
        path: '/defend/notice',
        component: () => import('../views/defend/notice/index.vue')
      },
      {
        path: '/system/helpdoc',
        component: () => import('../views/system/helpdoc/index.vue')
      },
      {
        path: '/system/banner',
        component: () => import('../views/system/banner/index.vue')
      },
      {
        path: '/system/researchReport',
        component: () => import('../views/system/researchReport/index.vue')
      },
      {
        path: '/system/role',
        component: () => import('../views/system/role/index.vue')
      },
      {
        path: '/data/gov',
        component: () => import('../views/data/gov/index.vue')
      },
      {
        path: '/data/task/gov',
        component: () => import('../views/data/task/index.vue')
      },
      {
        path: '/system/org',
        component: () => import('../views/system/org/index.vue')
      },
      {
        path: '/system/platform/user',
        component: () => import('../views/system/platform/user/index.vue')
      },
      {
        path: '/system/platform/info',
        component: () => import('../views/system/platform/info/index.vue')
      },
      {
        path: '/system/platform/routerUser',
        component: () => import('../views/system/platform/routeruser/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  // 访问登录页，放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})

export default router

import Vue from 'vue'
import { Link, CascaderPanel, Tree, Tag, Popover, Upload, ButtonGroup, Checkbox, Tooltip, BreadcrumbItem, Breadcrumb, Main, Header, Container, Aside, MenuItem, MenuItemGroup, Submenu, Menu, Divider, DatePicker, MessageBox, Message, Cascader, Option, Select, DropdownItem, DropdownMenu, Dropdown, FormItem, Form, Button, Row, Radio, RadioButton, RadioGroup, Table, TableColumn, Card, Col, Input, Pagination, Dialog } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//  导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//  导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Notifications from 'vue-notification'

//  将富文本编辑器，注册为全局可用的组件
Vue.use(Link)
Vue.use(CascaderPanel)
Vue.use(Notifications)
Vue.use(Tree)
Vue.use(Tag)
Vue.use(Popover)
Vue.use(VueQuillEditor)
Vue.use(Upload)
Vue.use(ButtonGroup)
Vue.use(Checkbox)
Vue.use(Tooltip)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(Main)
Vue.use(Header)
Vue.use(Container)
Vue.use(Aside)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Menu)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.use(Cascader)
Vue.use(Option)
Vue.use(Select)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Dropdown)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Button)
Vue.use(Row)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Card)
Vue.use(Col)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(Dialog)
